<template>
  <div class="userlist">
    <b-card no-body class="userlist-card">
      <b-list-group>
        <b-list-group-item variant="primary" class="search-item">
          <!-- search input, not algolia -->
          <b-input-group>
            <b-form-input v-model="search" placeholder="Search" class="search-input"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="updateSearch" class="search-btn">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-list-group-item>
        <b-list-group-item class='user-item' v-for="user in users" :key="user.id" :active='userId===user.id' @click='selectUser(user.id)' button>
          <div class="user-profile">{{user.profile_name}}</div>
          <div class="user-phone">{{ user.phone }}</div>
        </b-list-group-item>
        <b-list-group-item v-if="loading" class="forum-item">
          <b-row>
            <b-col class="text-center">
              <b-spinner></b-spinner>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<style scoped>
  .userlist-card {
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .search-item {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .search-input {
    border-radius: 0.5rem;
  }

  .search-btn {
    border-radius: 0.5rem;
    background-color: #007bff;
    border-color: #007bff;
  }

  .user-item {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1.25rem;
  }

  .user-profile,
  .user-phone {
    font-weight: 600;
  }

  .user-phone {
    color: #6c757d;
  }
</style>

<script>
  import api from '@/api.js'
  import EventBus from '@/event-bus.js'

  export default {
    name: 'UserList',
    data() {
      return {
        timer: null,
        cacheTimeout: null,
        loading: false,
        users: [],
        search: '',
      }
    },
    props: {
      userId: String,
      userData: {
        type: Object,
        default: () => {}
      }
    },
    mounted() {
      //this.timer = setInterval(this.updateSearch, 10000)
      EventBus.$on('userChanged', () => {
        this.updateSearch()
      })
      this.updateSearch()
    },
    beforeDestoy() {
      EventBus.$off('userChanged')
      
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    watch: {
      search() {
        //debounce
        if (this.cacheTimeout) {
          clearTimeout(this.cacheTimeout)
        }
        this.cacheTimeout = setTimeout(() => {
          this.updateSearch()
          this.cacheTimeout = null
        }, 500)
      }
    },
    methods: {
      async updateSearch() {
        try {
          this.loading = true
          const operation = this.$operationTracker.registerOperation(this, 'api/user/search')
          const { data } = await api.call('api/user/search', { needle: this.search });
          if (operation.isCanceled()) {
            return
          }
          this.users = data.map(entry => {
            return entry.data 
          }).filter(e => e)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      selectUser(userId) {
        //this.selectedFeed = feedId
        this.$emit('update:userId', userId)
        this.$emit('update:userData', this.users.find(e => e.id === userId))
        //this.parserStatus([feedId])

      }
    }
  }
</script>
