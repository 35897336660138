<template>
  <div class="filterinfo">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-card v-if='filterId'
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'> Filter</span></b-col>
          <b-col cols="auto" align-self="end">
            
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-tabs v-if='filter && filter.id && !loading' card>
        <b-tab title="Info" active>
          <b-form>
            <b-form-group
              label-cols="2"
              label="Identifier"
              label-for="filter-id">
              <b-form-input
                id="filter-id"
                v-model="filter.id"
                :disabled='true'
                required
                placeholder="Enter filter id"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label="Name"
              label-for="filter-name">
              <b-form-input
                id="filter-name"
                v-model="filter.name"
                required
                placeholder="Enter filter name"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label="Priority"
              label-for="filter-priority">
              <b-form-input
                id="filter-priority"
                v-model="filter.rules.priority"
                required
                placeholder="Enter filter priority"></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="!isDefault"
              label-cols="2"
              label="Is Global Default"
              label-for="filter-is_global_default">
              <b-form-checkbox
                id="filter-is_global_default"
                v-model="filter.is_global_default"
                :disabled='!this.isNew'
                required
                placeholder="Enter filter gd"></b-form-checkbox>
            </b-form-group>
            <!-- text input: MDM Filtering Departement -->
            <b-form-group
              v-if="!isDefault && !filter.is_global_default"
              label-cols="2"
              label="MDM Filtering Departement"
              label-for="filter-mdm_filtering_department">
              <b-form-input
                id="filter-mdm_filtering_department"
                v-model="filter.mdmData.department"
                required
                placeholder="Enter MDM Filtering Departement"></b-form-input>
            </b-form-group>


            <!-- if it is default or not -->
            <b-tabs card>
              <b-tab title="Simple" active>
                
                <!--label "Message Filters"  for everying below -->
                <div class="mt-2 text-bold">
                  <span><b>Message Filters:</b></span>
                </div>
                <!--messageFilterOptions checkboxes -->
                <b-form-group
                  label-cols="2"
                  label="Disabled types"
                  label-for="filter-messageFilterOptions">
                  <b-form-checkbox-group
                    id="filter-messageFilterOptions"
                    v-model="messageFilters"
                    :options="messageFilterOptions"></b-form-checkbox-group>
                </b-form-group>
                
                <!--label "Forum Filters"  for everying below -->
                <div class="mt-2 text-bold">
                  <span><b>Forum Filters:</b></span>
                </div>
                <b-form-group
                  label-cols="2"
                  label="Mode"
                  label-for="filter-mode">
                  <b-form-select
                    id="filter-mode"
                    v-model="filterMode"
                    :options="filterModes"
                    required
                    placeholder="Select filter mode"></b-form-select>
                </b-form-group>

                
                <!-- list of forums -->
                <ForumSimpleList
                  :forums="forums"
                  :forumId.sync="selectedForumId"
                  :loading="forumLoading"
                  @selectForum="selectedForumId = $event"></ForumSimpleList>
                <!-- add forum to list -->
                <b-row class="mt-2">
                  <b-col cols="auto">
                    <b-button variant="primary" @click="showForumSearchModal">Add Forum</b-button>
                  </b-col>
                  <b-col cols="auto">
                    <b-button variant="danger" @click="removeForumFromFilter" :disabled="!selectedForumId">Remove Forum</b-button>
                  </b-col>
                </b-row>

                <!--label "Flags"  for everying below -->
                <div class="mt-2 text-bold">
                  <span><b>Flags:</b></span>
                </div>
                <!--flags checkboxes -->
                <b-form-group
                  label-cols="2"
                  label="Flags"
                  label-for="filter-flags">
                  <b-form-checkbox-group
                    id="filter-flags"
                    v-model="flags"
                    :options="flagOptions"></b-form-checkbox-group>
                </b-form-group>

                <!-- forum seach -->
                <b-modal ref="searchForumModal" title="Search Forum" size="lg">
                  <ForumList :forumId.sync='modalForumId' :allowNewForum="false" :hitsPerPage="6"/>
                  
                  <b-button slot="modal-footer" variant="primary" @click="addForumToFilter">Add Forum</b-button>
                  <b-button slot="modal-footer" variant="secondary" @click="$refs['searchForumModal'].hide()">Close</b-button>
                </b-modal>
                
              </b-tab>
              <b-tab title="Advanced">
                <b-form-group
                  label-cols="2"
                  label="Message Rules"
                  label-for="filter-message-rules">
                  <b-form-textarea
                    id="filter-message-rules"
                    v-model="message_rules"
                    required
                    placeholder="Enter filter message rules"></b-form-textarea>
                </b-form-group>
                <b-form-group
                  label-cols="2"
                  label="Forum Rules"
                  label-for="filter-forum-rules">
                  <b-form-textarea
                    id="filter-forum-rules"
                    v-model="forum_rules"
                    required
                    placeholder="Enter filter forum rules"></b-form-textarea>
                </b-form-group>
              </b-tab>
            </b-tabs>
            <!-- here we have set of buttons in a row. save (or create if it is new), delete (if not new), attach to user, detach from user  -->
            <b-row class="mt-2">
              <b-col cols="auto">
                <b-button variant="primary" @click="saveFilter" v-if="canSave">Save</b-button>
                <b-button variant="primary" @click="createFilter" v-else>Create</b-button>
              </b-col>
              <b-col cols="auto" v-if="canDelete">
                <b-button variant="danger" @click="deleteFilter">Delete</b-button>
              </b-col>
              <b-col cols="auto" v-if="canAttach">
                <b-button variant="primary" @click="attachFilterToUser">Attach to User</b-button>
              </b-col>
              <b-col cols="auto" v-if="canDetach">
                <b-button variant="danger" @click="detachFilterFromUser">Detach from User</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  //import moment from 'moment-timezone'
  import Loading from 'vue-loading-overlay'
  import api from '@/api.js'
  import ForumSimpleList from '@/components/ForumSimpleList.vue'
  import ForumList from '@/components/ForumList.vue'
  import EventBus from '@/event-bus'
  //uuid 
  import { v4 as uuidv4 } from 'uuid';
  export default {
    name: 'FilterInfo',
    props: {
      filterId: String,
      userId: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        modalLoading:false,
        forumLoading: false,
        loading: false,
        isNew: false,
        filter: null,
        selectedForumId: null,
        modalForumId: null,
        message_rules: '',
        forum_rules: '',
        filterMode: 'allow',
        filterModes: [
          { value: 'allow', text: 'Allow only' },
          { value: 'restrict', text: 'Restrict to' },
          { value: 'custom', text: 'Custom' },
        ],
        forumsIds: [],
        forums: [],
        //4 checkboxes
        messageFilterOptions: [
          { value: 'photo', text: 'Photo' },
          { value: 'video', text: 'Video' },
          { value: 'audio', text: 'Audio' },
          { value: 'gif', text: 'Gif' },
          { value: 'document', text: 'Document' },
        ],
        messageFilters: [],
        flagOptions: [
          { value: 'no_dm', text: 'No DM (all)' },
          { value: 'no_dm_forum', text: 'No DM (forum)' },
          { value: 'no_dm_feed', text: 'No DM (feed)'},
          { value: 'no_dialog', text: 'No Dialog (all)'},
          { value: 'no_dialog_forum', text: 'No Dialog (forum)'},
          { value: 'no_dialog_feed', text: 'No Dialog (feed)'},
          { value: 'no_channel', text: 'No Channel'},
          { value: 'no_forum', text: 'No Forum'},
          { value: 'no_feed', text: 'No Feed'},
          { value: 'no_invite', text: 'No Invite (all)' },
          { value: 'no_invite_channel', text: 'No Invite (channel)' },
          { value: 'no_invite_forum', text: 'No Invite (forum)' },
          { value: 'no_invite_feed', text: 'No Invite (feed)' },
          { value: 'no_join', text: 'No Join' },
          { value: 'no_join_channel', text: 'No Join (channel)'},
          { value: 'no_join_forum', text: 'No Join (forum)'},
          { value: 'no_join_feed', text: 'No Join (feed)'},
          { value: 'no_phone', text: 'Hide phone' },
          { value: 'no_search_forum', text: 'No search forum' },
          { value: 'no_premium', text: 'No Premium' }
        ],
        flags: [],
      }
    },
    watch: {
      filterId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.isNew = false
          this.filter = null
          this.bind(id)
        }
      },
      filterMode: {
        handler: async function () {
          this.buildRulesFromForums()
        }
      },
      messageFilters: {
        handler: async function () {
          this.buildMessageRules()
        }
      },
    },
    methods: {
      async saveFilter() {
        this.loading = true
        try {
          this.buildRulesFromForums()
          console.log('saving filter', this.forum_rules, this.message_rules)
          if (this.filter.user_id || this.filter.is_global_default) {
             //it is default filter for user, so we need to delete it and create new one
            let res1 = await api.call('api/filtering/delete', { id: this.filter.id })
            if (!res1 || !res1.data || !res1.data.id) {
              throw new Error('Error deleting filter')
            }
            let res = await api.call('api/filtering/create', { 
              name: this.filter.name, 
              rules: { 
                message_filters: this.message_rules, 
                forum_filters: this.forum_rules, 
                priority: this.filter.rules.priority,
                flags: this.flags 
              },
              user_id: !this.filter.is_global_default && this.userId || null,
              is_global_default: this.filter.is_global_default,
              mdmData: this.filter.mdmData || null,
            })
            if (!res || !res.data || !res.data.data) {
              throw new Error('Error creating filter')
            }
            this.filter = res.data.data
            this.$bvToast.toast('Filter created', {
              title: 'Success',
              variant: 'success',
              solid: true
            })
            EventBus.$emit('filter-created', this.filter.id)
            this.$emit('update:filterId', this.filter.id)
            this.transformRules()
          } else {
            let res = await api.call('api/filtering/edit', { 
              id: this.filter.id, 
              name: this.filter.name, 
              rules: { 
                message_filters: this.message_rules, 
                forum_filters: this.forum_rules, 
                priority: this.filter.rules.priority,
              },
              mdmData: this.filter.mdmData || null,
            })
            if (!res || !res.data || !res.data.data) {
              throw new Error('Error creating filter')
            }
            this.filter = res.data.data
            if (this.filter && (!this.filter.mdmData || !this.filter.mdmData.department)) {
              if (!this.filter.mdmData) {
                this.filter.mdmData = {}
              }
              if (!this.filter.mdmData.department) {
                this.filter.mdmData.department = ''
              }
            }
            this.$bvToast.toast('Filter saved', {
              title: 'Success',
              variant: 'success',
              solid: true
            })
          }
        } catch (e) {
          this.$bvToast.toast('Error saving filter', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        } finally {
          this.loading = false
        }
      },

      async createFilter() {
        this.loading = true
        try {
          let res = await api.call('api/filtering/create', { 
            name: this.filter.name, 
            rules: { 
              message_filters: this.message_rules, 
              forum_filters: this.forum_rules, 
              priority: this.filter.priority,
              flags: this.flags 
            },
            user_id: this.userId || null,
            is_global_default: this.filter.is_global_default,
            mdmData: !this.filter.is_global_default ? this.filter.mdmData : null,
            action: 'set'
          })
          if (!res || !res.data || !res.data.data) {
            throw new Error('Error creating filter')
          }
          this.filter = res.data.data
          if (this.filter && (!this.filter.mdmData || !this.filter.mdmData.department)) {
            if (!this.filter.mdmData) {
              this.filter.mdmData = {}
            }
            if (!this.filter.mdmData.department) {
              this.filter.mdmData.department = ''
            }
          }
          this.$bvToast.toast('Filter created', {
            title: 'Success',
            variant: 'success',
            solid: true
          })
          EventBus.$emit('filter-created', this.filter.id)
          this.$emit('update:filterId', this.filter.id)
          this.transformRules()
        } catch (e) {
          this.$bvToast.toast('Error creating filter', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        } finally {
          this.loading = false
        }
        
      },

      async deleteFilter() {
        this.loading = true
        try {
          const id = this.filter.id
          let res = await api.call('api/filtering/delete', { id:  id })
          this.$bvToast.toast('Filter deleted', {
            title: 'Success',
            variant: 'success',
            solid: true
          })
          if (!res || !res.data || !res.data.id) {
            throw new Error('Error deleting filter')
          }
          this.filter = null
          //console.log(res.data)
          //EventBus is used to notify parent component that filter was deleted and emit selected filter id
          EventBus.$emit('filter-deleted', id)
          this.$emit('update:filterId', null)
        } catch (e) {
          this.$bvToast.toast('Error deleting filter', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        } finally {
          this.loading = false
        }
      },

      async attachFilterToUser() {
        this.loading = true
        try {
          let res = await api.call('api/filtering/mod-user', { id: this.filter.id, user_id: this.userId, action: 'set' })
          this.$bvToast.toast('Filter attached', {
            title: 'Success',
            variant: 'success',
            solid: true
          })
          console.log(res.data)
          //EventBus is used to notify parent component that filter was deleted and emit selected filter id
          EventBus.$emit('filter-attached', { filter_id: this.filter.id, user_id: this.userId })
        } catch (e) {
          this.$bvToast.toast('Error attaching filter', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        } finally {
          this.loading = false
        }
      },

      async detachFilterFromUser() {
        this.loading = true
        try {
          let res = await api.call('api/filtering/mod-user', { id: this.filter.id, user_id: this.userId, action: 'remove' })
          this.$bvToast.toast('Filter detached', {
            title: 'Success',
            variant: 'success',
            solid: true
          })
          console.log(res.data)
          //EventBus is used to notify parent component that filter was deleted and emit selected filter id
          EventBus.$emit('filter-detached', { filter_id: this.filter.id, user_id: this.userId })
        } catch (e) {
          this.$bvToast.toast('Error detaching filter', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        } finally {
          this.loading = false
        }
      },

      showForumSearchModal() {
        this.$refs['searchForumModal'].show()
      },
      addForumToFilter() {
        if (this.modalForumId) {
          if (!this.forumsIds.includes(this.modalForumId)) {
            this.forumsIds.push(this.modalForumId)
            this.$refs['searchForumModal'].hide()
            this.modalForumId = null
            this.buildRulesFromForums()
            this.loadForums()
          }
        }
      },
      removeForumFromFilter() {
        if (this.selectedForumId) {
          this.forumsIds = this.forumsIds.filter(f => f !== this.selectedForumId)
          this.forums = this.forums.filter(f => f.id !== this.selectedForumId)
          this.selectedForumId = null
          this.buildRulesFromForums()
        }
      },
      transformRules() {
        if (this.filter?.rules) {
          this.message_rules = this.filter.rules.message_filters?.join(' ') || ''
          this.forum_rules = this.filter.rules.forum_filters?.join(' ') || ''
          this.flags = this.filter.rules.flags || []
          this.forumsIds = this.filter.rules.forum_filters?.map(f => {
            if (f && f.startsWith('forum-')) {
              return f.replace('forum-', '')
            }
            return null
          }).filter(f => f !== null) || []
        } else {
          this.message_rules = ''
          this.forum_rules = ''
          this.forumsIds = []
          this.flags = []
        }
        this.detectMode()
        this.detectMessageFilters()
      },
      buildRulesFromForums() {
        if (this.filterMode === 'allow') {
          this.forum_rules = this.forumsIds.map(f => `forum-${f}`).join(' OR ')
        } else if (this.filterMode === 'restrict') {
          this.forum_rules = this.forumsIds.map(f => `NOT forum-${f}`).join(' AND ')
        } else {
          //do nothing, custom mode
        }
      },
      buildMessageRules() {
        console.log(this.messageFilters)
        if (this.messageFilters.length) {
          this.message_rules = this.messageFilters.map(f => `NOT ${f}`).join(' AND ')
        } else {
          this.message_rules = ''
        }
      },
      //detect mode (allow of restrict) based on message_rules expression
      detectMode() {
        //filter string is something like: "forum-uuid OR forum-uuid OR forum-uuid" or "NOT ( forum-uuid AND forum-uuid AND forum-uuid )", first one is allow, second is restrict, if string is empty - allow, if string is neither of these (or uses tags withour prefix "forum-") - custom
        if (this.forum_rules === '') {
          this.filterMode = 'allow'
          return
        }
        const split_rules = this.forum_rules.split(' ')
        //explicitely detect a pattern, because for custom rules
        //we can have a string like "forum-uuid OR forum-uuid OR forum-uuid" which is valid for allow mode
        let isAllow = true
        let isRestrict = true
        const OPS = [ 'AND', 'OR', 'NOT', '(', ')']
        //allow is forum-X OR forum-X OR forum-X ...
        //restrict is NOT forum-X AND NOT forum-X AND NOT forum-X ... )
        //custom is anything else
        for (let i = 0; i < split_rules.length; i++) {
          if (!OPS.includes(split_rules[i]) && !split_rules[i].startsWith('forum-')) {
            isAllow = false
            isRestrict = false
            break
          }
          if (i === 0 && !split_rules[i].startsWith('forum-')) {
            isAllow = false
          }
          if (i % 2 === 1 ) {
            if (split_rules[i] !== 'OR') {
              isAllow = false
            }
          }
          if (i % 3 === 0 ) {
            if (split_rules[i] !== 'NOT') {
              isRestrict = false
            }
          }
          if (i > 3 && (i + 1) % 3 === 0 ) {
            if (split_rules[i] !== 'AND') {
              isRestrict = false
            }
          }
        }
        if (isAllow) {
          this.filterMode = 'allow'
        } else if (isRestrict) {
          this.filterMode = 'restrict'
        } else {
          this.filterMode = 'custom'
        }

      },
      //detect message filters
      // string is always in format: "NOT ( type AND type AND type )"
      // we need to fill messageFilters array with objects like { type: 'audio', value: true }
      detectMessageFilters() {
        this.messageFilters = []
        if (this.message_rules === '') {
          this.messageFilters = []
          return
        }
        const split_rules = this.message_rules.split(' ')
      
        let isRestrict = true
        const OPS = [ 'AND', 'OR', 'NOT', '(', ')']
    
        for (let i = 0; i < split_rules.length; i++) {
          if (i % 3 === 0 ) {
            if (split_rules[i] !== 'NOT') {
              isRestrict = false
            }
          }
          if (i > 3 && (i + 1) % 3 === 0 ) {
            if (split_rules[i] !== 'AND') {
              isRestrict = false
            }
          }
        }
        if (isRestrict) {
          split_rules.forEach(r => {
            if (!OPS.includes(r)) {
              this.messageFilters.push(r)
            }
          })
          
        } else {
          //add all filters
          this.messageFilters = []
        }

      },

      

      bind: async function(id) {
        console.log(id)
        if (id === '$//newFilter') {
          this.ref = null
          this.isNew = true
          this.filter = {
            id: uuidv4(),
            name: '',
            is_global_default: false,
            rules: {
              message_filters: [],
              forum_filters: [],
              priority: 0,
            },
            mdmData: {
              department: '',
            }
          }
          this.transformRules()
          this.loading = false
          return
        } else if (id === '$//newDefFilter') {
          this.ref = null
          this.isNew = true
          this.filter = {
            id: uuidv4(),
            name: `Default filter for ${this.userId}`,
            user_id: this.userId,
            is_global_default: false,
            rules: {
              message_filters: [],
              forum_filters: [],
              priority: 0,
            },
            mdmData: {
              department: '',
            }
          }
          this.transformRules()
          this.loading = false
          return
        }
        if (id) {
          this.loading = true
          try {
            const { data } = await api.call('api/filtering/get', { id });
            this.filter = data.data
            if (this.filter && (!this.filter.mdmData || !this.filter.mdmData.department)) {
              if (!this.filter.mdmData) {
                this.filter.mdmData = {}
              }
              if (!this.filter.mdmData.department) {
                this.filter.mdmData.department = ''
              }
            }
            this.transformRules()
            this.loadForums()
            console.log(this.filter)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        } else {
          this.ref = null
          this.filter = null
        }
      },

      loadForums: async function() {
        this.forumLoading = true
        try {
          const { data } = await api.call('api/forum/list', { ids: this.forumsIds, query: 'ids' });

          console.log(data)
          this.forums = data.map(e => e.data)
        } catch (e) {
          console.log(e)
        } finally {
          this.forumLoading = false
        }
      },
    },
    computed: {
      isDefault() {
        return this.filter && this.filter.user_id;
      },
      isGlobalDefault() {
        return this.filter && this.filter.is_global_default;
      },
      /*isNew() {
        return this.filterId === '$//newFilter' || this.filterId === '$//newDefFilter'
      },*/
      canSave() {
        return this.filter && this.filter.name && this.filter.name.length > 0 && !this.isNew
      },
      canCreate() {
        return this.filter && this.filter.name && this.filter.name.length > 0 && this.isNew
      },
      canDelete() {
        return this.filter && !this.isNew
      },
      canAttach() {
        return this.filter && !this.isNew && this.userId && !this.isDefault && !this.isGlobalDefault
      },
      canDetach() {
        return this.filter && !this.isNew && this.userId && !this.isDefault && !this.isGlobalDefault
      },
    },
    filters: {
    },
    components: {Loading, ForumSimpleList, ForumList}
  }
</script>
